import { graphql, useStaticQuery } from 'gatsby'

type Link = string | undefined

const useCmsSocialMediaLinks = () => {
  const data = useStaticQuery(
    graphql`
      query SocialMediaLinks {
        cmsSocialMediaLinks {
          sections {
            data
          }
        }
      }
    `
  )

  const linkWhatsApp: Link =
    data?.cmsSocialMediaLinks?.sections?.[0]?.data?.whatsApp
  const linkFacebook: Link =
    data?.cmsSocialMediaLinks?.sections?.[0]?.data?.facebook
  const linkInstagram: Link =
    data?.cmsSocialMediaLinks?.sections?.[0]?.data?.instagram
  const linkLinkedin: Link =
    data?.cmsSocialMediaLinks?.sections?.[0]?.data?.linkedin

  return { linkWhatsApp, linkFacebook, linkInstagram, linkLinkedin }
}

export default useCmsSocialMediaLinks
