import { graphql, useStaticQuery } from 'gatsby'

const useCmsFullCategoriesMenu = () => {
  const data = useStaticQuery(
    graphql`
      query FullCategories {
        cmsFullCategoriesMenu {
          sections {
            name
            data
          }
        }
      }
    `
  )

  const allCategories =
    data?.cmsFullCategoriesMenu?.sections?.[0]?.data?.departament

  return { allCategories }
}

export default useCmsFullCategoriesMenu
