import { graphql, useStaticQuery } from 'gatsby'
import type { MaintenanceStore } from '@plurix/ecom-pages/src/types/CMS'

const useCmsMaintenanceStore = () => {
  const data = useStaticQuery(
    graphql`
      query CmsMaintenanceStoreQuery {
        cmsDisableStore {
          sections {
            data
          }
        }
      }
    `
  )

  const maintenanceStore: MaintenanceStore | undefined =
    data?.cmsDisableStore?.sections?.[0]?.data

  return {
    maintenanceStore,
  }
}

export default useCmsMaintenanceStore
