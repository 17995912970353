import { graphql, useStaticQuery } from 'gatsby'

type GenericObject = { [key: string]: boolean }

const useCmsFeatureToggle = () => {
  const data = useStaticQuery(
    graphql`
      query FeatureToggleQuery {
        cmsFeatureToggle {
          sections {
            data
          }
        }
      }
    `
  )

  const { hasPaymentMethods, ...featureToggle }: GenericObject =
    data?.cmsFeatureToggle?.sections?.[0]?.data

  return { hasPaymentMethods, featureToggle }
}

export default useCmsFeatureToggle
