import { graphql, useStaticQuery } from 'gatsby'

const useCmsWhatsappOffers = () => {
  const data = useStaticQuery(
    graphql`
      query WhatsappOffersQuery {
        cmsWhatsappOffers {
          sections {
            data
          }
        }
      }
    `
  )

  return data?.cmsWhatsappOffers?.sections
}

export default useCmsWhatsappOffers
