import { UtmCmsData } from '@plurix/ecom-pages/src/types/CMS'
import { graphql, useStaticQuery } from 'gatsby'

const useCmsUtmPromotion = () => {
  const data = useStaticQuery(
    graphql`
      query CmsUtmPromotion {
        cmsUtmPromotion {
          sections {
            data
          }
        }
      }
    `
  )

  const utmPromotionBond: UtmCmsData[] = data?.cmsUtmPromotion?.sections

  return { utmPromotionBond }
}

export default useCmsUtmPromotion
