import { graphql, useStaticQuery } from 'gatsby'

import type { CmsHomeQueryQuery } from '../../@generated/graphql/index'
import type { Logo, CMSSection } from '@plurix/ecom-pages/src/types/CMS'

const useCmsHome = () => {
  const data = useStaticQuery<CmsHomeQueryQuery>(
    graphql`
      query CmsHomeQuery {
        cmsHomeContent {
          sections {
            id
            name
            data
          }
        }
      }
    `
  )

  const logo: Logo | undefined = data?.cmsHomeContent?.sections?.find(
    ({ name }) => name === 'Logo'
  )?.data?.logo

  const sections = data?.cmsHomeContent?.sections as CMSSection[] | undefined // Prevent null in "id" type

  return { logo, sections }
}

export default useCmsHome
