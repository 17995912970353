import type { ScratchPageContent } from '@plurix/ecom-pages/src/types/CMS'
import { graphql, useStaticQuery } from 'gatsby'

const useCmsScratchPage = () => {
  const data = useStaticQuery(
    graphql`
      query CmsScrathQuery {
        cmsScratchPage {
          sections {
            id
            name
            data
          }
        }
      }
    `
  )

  const scratchPageContent: ScratchPageContent | undefined =
    data?.cmsScratchPage?.sections?.[0]?.data

  return { scratchPageContent }
}

export default useCmsScratchPage
