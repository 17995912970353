import { graphql, useStaticQuery } from 'gatsby'

const useRestrictedMode = () => {
  const data = useStaticQuery(
    graphql`
      query RestrictedMode {
        cmsRestrictedMode {
          sections {
            data
          }
        }
      }
    `
  )

  const passKey: string =
    data?.cmsRestrictedMode?.sections?.[0]?.data?.passKey ?? ''

  return { passKey }
}

export default useRestrictedMode
