import { graphql, useStaticQuery } from 'gatsby'
import type { PlpBanners } from '@plurix/ecom-pages/src/types/CMS'

const useCmsPlp = () => {
  const data = useStaticQuery(
    graphql`
      query CmsPlpQuery {
        cmsPlp {
          sections {
            data
          }
        }
      }
    `
  )

  const plpBanners: PlpBanners | undefined = data?.cmsPlp?.sections?.[0]?.data

  return { plpBanners }
}

export default useCmsPlp
