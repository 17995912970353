import axios from 'axios'
import { useEffect, useState } from 'react'

export interface DynamicNotification {
  notification: Notification
  modalInfos: ModalInfos
}

export interface ModalInfos {
  banner: string
  title: string
  description: string
  platforms: Platforms
}

export interface Platforms {
  mobile: boolean
  responsiveSite: boolean
  site: boolean
}

export interface Notification {
  displayLocation: string
  floatingNotification: boolean
  notificationType: string
  backgroundColor: string
  labelColor: string
  description: string
  leftIcon: string
  rightIcon: string
  platforms: Platforms
}

export const useDynamicNotification = () => {
  const [dynamicNotification, setDynamicNotification] =
    useState<DynamicNotification>()

  useEffect(() => {
    const request = () => {
      axios
        .get('/api/v1/mkt/dynamicNotification?builder=faststore')
        .then(({ data }) => {
          setDynamicNotification(data)
        })
    }

    request()
  }, [])

  return dynamicNotification
}
