import { graphql, useStaticQuery } from 'gatsby'
import type { BannerWithLink } from '@plurix/ecom-pages/src/types/CMS'

interface MenuProps {
  title: string
  url: string
}

const useCmsExternalMenus = () => {
  const data = useStaticQuery(
    graphql`
      query Navigation {
        cmsNavigation {
          sections {
            name
            data
          }
        }
      }
    `
  )

  const menuFirst: MenuProps[] | undefined =
    data?.cmsNavigation?.sections?.[0]?.data?.Menu
  const menuSecond: MenuProps[] | undefined =
    data?.cmsNavigation?.sections?.[1]?.data?.Menu

  const menuPromotionalBanner: BannerWithLink | undefined =
    data?.cmsNavigation?.sections?.[2]?.data

  const menuCouponsBanner: BannerWithLink | undefined =
    data?.cmsNavigation?.sections?.[3]?.data

  return { menuFirst, menuSecond, menuPromotionalBanner, menuCouponsBanner }
}

export default useCmsExternalMenus
