import { graphql, useStaticQuery } from 'gatsby'
import useCmsFeatureToggle from './useCmsFeatureToggle'
import { useMemo } from 'react'

const useCmsPaymentMethods = () => {
  const data = useStaticQuery(
    graphql`
      query PaymentMethods {
        cmsPaymentMethods {
          sections {
            data
          }
        }
      }
    `
  )

  const { hasPaymentMethods } = useCmsFeatureToggle()

  const paymentMethods: Array<{ icon: string; name: string }> | undefined =
    useMemo(() => {
      if (!hasPaymentMethods) {
        return null
      }

      return data?.cmsPaymentMethods?.sections?.[0]?.data?.methods
    }, [hasPaymentMethods])

  return { paymentMethods }
}

export default useCmsPaymentMethods
